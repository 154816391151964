
import React, { Component } from 'react'
// icons

import { URLAPI } from '../../utility/ApiMethods'
import { Put, Get } from '../../utility/fetch'
import TextInput from "../inputs/TextInput";
import { BiMailSend } from "react-icons/bi";
import TextareaInput from "../inputs/TextareaInput";
import imageSrc from '../../assets/images/profile-pix.jpg'
import { errorMessage, successMessage } from '../../resources/alert';
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';
import Cookies from 'js-cookie';
import { SwapTableContext } from '../Contexts/SwapTableContext';



class GrievanceReviewDetails extends Component {
    static contextType = SwapTableContext;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            from: "",
            fromId: 0,
            through: "",
            throughId: 0,
            to: "",
            user: "",
            comment: "",
            content: "",
            toId: 0,
            subject: "",
            docs: "",
            memoid: 0,
            offcoment: "",
            signed: "",
            button: "Acknowledge",
            memo: [],
            reload: false,

        }
        this.handleChange = this.handleChange.bind(this);
        //this.setState = this.setState.bind(this);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });

    }


    onsubmitApproval = () => {

        let error_data = "";

        if (this.state.comment === "") {
            error_data = "comment cannot be empty";
        }

        if (error_data !== "") {
            errorMessage(error_data)
        }
        else if (error_data === "") {

            let app = {
                comment: this.state.comment
            }

            if (this.state.button === "Acknowledge") {
                this.approveMemo(app)
            }
        }

    }

    approveMemo = (memo) => {
        console.log("logging", memo);

        if (this.state.memoid) {
            this.setState({ loading: true }, () => {
                Put(memo, URLAPI.MemoApproval + "/" + this.state.memoid, this.onSubmitAfterApproval);
            });
        }
    }


    onSubmitAfterApproval = (data) => {

        // console.log('entered submit after approval function')

        if (data.status === "success") {


            this.setState({ memo: this.props?.filterReviewsOnApprover, loading: false })
            console.log(data)

            successMessage("acknowledged successfully")
            Get(URLAPI.GrievanceReview.Get, this.getReviews);
            // this.setState({ 
            //     reload:true
            //          })
        }
        else if (data.status === "failed") {

            errorMessage(data.message)

            this.setState({
                ...this.state,
                loading: false,

            })

        }
        else {

            errorMessage(data.message)
            this.setState({
                ...this.state,
                loading: false
            })

        }
    }

    componentDidMount() {
        console.log(this.props?.isTreated
        )
        this._isMounted = true;
        this.setState({
            memoid: this.props?.id,
            to: this.props?.to,
            from: this.props?.from,
            memoDate: this.props?.memoDate,
            through: this.props?.through,
            content: this.props?.content,
            comments: Array.isArray(this.props?.comments) && this.props?.comments[0],
            subject: this.props?.subject,
            docs: this.props?.docPath,
            //offcomment: this.props?.comment,
            //memo: this.props?.filterReviewsOnApprover

        })
        Get(URLAPI.GrievanceReview.Get, this.getReviews);
    }

    //     componentDidUpdate(prevState, prevProps) {
    // // if (prevState.reload!=this.state.reload  )
    //         // Get(URLAPI.GrievanceReview.Get, this.getReviews);
    //     }



    getReviews = (data) => {
        const { handlePopulate, populate, handleNumba } = this.context

        console.log("logging data", data)

        if (this._isMounted) {

            let _reviews = Array.isArray(data.data) ? data.data : [];
            this.setState({ memo: _reviews, loading: false, });

            // ={isTreated}
            (this.props?.isTreated == false) && handleNumba(true)

            // this.props?.func(_reviews)


        }

    }


    componentWillUnmount() {
        this._isMounted = false;
    }


    render() {
        const { handlePopulate, populate, } = this.context

        const { loading } = this.state

        return (<>
            {loading ? <LoadingSpinnerEdo /> :
                <div className="m-t-10" style={{ transition: "all 0.6s ease-in" }}>

                    <div className="flex" style={{ justifyContent: "center", alignItems: "center" }}>
                        <div className="flex ">

                            {/**  <div className="no-padding">
                            <div
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                    // padding: "0",
                                }}
                            >

                                <div style={{ padding: ".8rem" }}>

                                    <div className="flex m-t-10">
                                        <h5 className="bold-text" style={{ color: "#909090" }}>
                                            Memo Initiator:
                    </h5>
                                        <p style={{ color: "#909090", fontSize: "11px" }}>
                                            <strong>{this.state.from}</strong>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
*/}


                            <div
                                className="m-t-20"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                    padding: "0",
                                }}
                            >
                            </div>

                        </div>
                        <div className="col-9 pd-r-0">
                            <div
                                className="p-20"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                    // padding: "0",
                                }}
                            >
                                <div>
                                    <TextInput
                                        label="To"
                                        name="to"
                                        value={this.state.to}
                                        disabled
                                    />
                                    <TextInput
                                        label="from"
                                        name="from"
                                        value={this.state.from}
                                        disabled
                                    />

                                    {/* <TextInput
                                        label="through"
                                        name="through"
                                        value={this.state.through}
                                        disabled
                                    /> */}
                                </div>

                                <div>
                                    {
                                        this.props?.isTreated == false ?
                                            <div className="m-t-10">
                                                <TextareaInput
                                                    label="Detail"
                                                    name="Detail"
                                                    value={this.state.content}
                                                    disabled
                                                >
                                                </TextareaInput>
                                                <br />
                                            </div>
                                            :

                                            <div>
                                                <div className="m-t-10">
                                                <TextareaInput
                                                    label="Detail"
                                                    name="Detail"
                                                    value={this.state.content}
                                                    disabled
                                                >
                                                </TextareaInput>
                                                <br />
                                            </div>
                                            <div className="m-t-10">
                                                <TextareaInput
                                                    label="Approver's Comment"
                                                    name="Comment"
                                                    value={this.state?.comments?.comment}
                                                    disabled
                                                >
                                                </TextareaInput>
                                                <br />
                                            </div>
                                            </div>

                                            
                                    }

                                    {/* :
                              } */}
                                </div>
                                <div className="m-t-20">
                                    <h3 className="m-t-20" style={{ fontWeight: "bold" }}><strong>Supporting Documents</strong></h3>
                                    {this.props?.documents?.map((x) => (
                                        <i style={{ display: "flex" }}><BiMailSend /><a href={`${x?.docPath}`} style={{ display: "flex" }} download={x?.docName} target="_blank" className="m-l-10">
                                            <h3 style={{ marginLeft: "5px" }}>
                                                {x?.docName}
                                            </h3>
                                        </a>{/**<BiTrash /> */} </i>
                                    ))
                                    }

                                </div>
                            </div>
                            <div
                                className="m-t-10"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                }}
                            >



                            </div>
                            {this.props?.isTreated == false && <div
                                className="m-t-10"
                                style={{
                                    boxShadow: "0px 2px 6px #0000000A",
                                    backgroundColor: "white",
                                }}
                            >
                                <div className="p-20">

                                    <div
                                        className="m-b-10 m-t-5"
                                        style={{
                                            color: "#43425D",
                                            fontSize: "13px",
                                            lineHeight: "1.3",
                                        }}
                                    >


                                        <div className="p-20">
                                            <div className="flex bold-text" style={{ color: "#1C811C" }}>
                                                <TextareaInput className="w-100 m-b-10" label={"Supervisor's comment"}
                                                    value={this.state.comment}
                                                    name="comment"
                                                    onChange={(e) => this.handleChange(e)} />
                                            </div>
                                        </div>
                                        {/* <div>
                                            Upload documents
                                        </div> */}

                                    </div>
                                </div>
                            </div>}

                            {this.props?.isTreated == false && <div className="w-100">
                                {loading ? <LoadingSpinnerEdo /> :
                                    <button className="btn btn-large w-100" type="button" onClick={this.onsubmitApproval} >
                                        {this.state.button}
                                    </button>
                                }
                            </div>}
                        </div>
                    </div>
                </div>
            }
        </>
        );
    }
}

export default GrievanceReviewDetails;
