// /* eslint-disable react/destructuring-assignment */
// import React from 'react';
// import { BsSearch } from 'react-icons/bs';

// const SearchDoc = (props) => {
//   const docList = props.docList && props.docList;

//   return (
//     <div className={`${props.className} form-group form-group-search`}>
//       <label htmlFor={props.name}>{props.label}</label>
//       <BsSearch
//         style={{
//           color: `${!props.color ? 'black' : props.color}`,
//           position: 'absolute',
//           top: `${props.formState ? '20px' : '30px'}`,
//         }}
//       />
//       {/* {console.log('set State value in searchInput :', props.formState)} */}
//       <input
//         type='search'
//         name={props.name}
//         id={props.name}
//         value={props.value}
//         onChange={props.onChange}
//         readOnly={props.readOnly}
//         placeholder={props.placeholder}
//         onBlur={props.onBlur}
//         disabled={props.disabled}
//         onKeyPress={props.onKeyPress}
//         onKeyDown={props.onKeyDown}
//         required={props.required}
//         className='sfield'
//         // onInput={() => searchInput(value)}
//       />
//       <div id='suggestions' className='suggestions'>
//         {docList &&
//           docList.map((suggestion, idx) => (
//             <div
//               key={idx}
//               className='suggestion-item'
//               onClick={() => {
//                 props.setState
//                   ? props.setStateValue({
//                       ...props.formState,
//                       [props.stateName]: `${suggestion.title}`,
//                       [props.stateName2]: `${suggestion.fileurl}`,
//                     })
//                 //   : props.stateName(`${suggestion.title}`);
//                 // props.setDocList([]);
//                 // props.setDocTitle(`${suggestion.title}`);

//                 : props.stateName(
//                   // 'employeeName',
//                   props.displayName,

//                   props.stateName === 'docTitle'
//                     ? `${suggestion.title}`
//                     : props.stateName2 === 'docPath'
//                     ? `${suggestion.fileurl} `
//                     : `${suggestion.title}`,
//                 );

              

//                 props.docId && props.docId(Number(suggestion.id));
//                 // props.setSelectedValue && props.setSelectedValue(Number(suggestion.value));
//                 props.selectDoc && props.selectDoc(Number(suggestion.fileurl));
//                 // console.log('logging selected', suggestion);
//               }}>
//               {`${suggestion.title}`}
           
//             </div>
//           ))}
//       </div>
//     </div>
//   );
// };

// export default SearchDoc;


/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { BsSearch } from 'react-icons/bs';

const SearchDoc = (props) => {
  const [mFilesVal, setMfilesVal] = useState('')
  const docList = props.docList && props.docList;

  useEffect(()=>{
    setMfilesVal('')
  },[props.setEmpty])
// if(prop)
  // setMfilesVal(props.setEmpty)
  console.log(props.setEmpty)
  return (
    <div className={`${props.className} form-group form-group-search`}>
      <label htmlFor={props.name}>{props.label}</label>
      <BsSearch
        style={{
          color: `${!props.color ? 'black' : props.color}`,
          position: 'absolute',
          top: `${props.formState ? '20px' : '30px'}`,
        }}
      />
      {/* {console.log('set State value in searchInput :', props.formState)} */}
      <input
        type='search'
        name={props.name}
        id={props.name}
        value={mFilesVal}
        onChange={(e)=>{
          props.mFilesFunc(e.target.value)
          setMfilesVal(e.target.value)
        }}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        onBlur={props.onBlur}
        disabled={props.disabled}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        required={props.required}
        className='sfield'
        // onInput={() => searchInput(value)}
      />
      {/* <div id='suggestions' className='suggestions'>
        {docList &&
          docList.map((suggestion, idx) => (
            <div
              key={idx}
              className='suggestion-item'
              onClick={() => {
                props.setState
                  ? props.setStateValue({
                      ...props.formState,
                      [props.stateName]: `${suggestion.title}`,
                      [props.stateName2]: `${suggestion.fileurl}`,
                    })
                //   : props.stateName(`${suggestion.title}`);
                // props.setDocList([]);
                // props.setDocTitle(`${suggestion.title}`);

                : props.stateName(
                  // 'employeeName',
                  props.displayName,

                  props.stateName === 'docTitle'
                    ? `${suggestion.title}`
                    : props.stateName2 === 'docPath'
                    ? `${suggestion.fileurl} `
                    : `${suggestion.title}`,
                );

              

                props.docId && props.docId(Number(suggestion.id));
                // props.setSelectedValue && props.setSelectedValue(Number(suggestion.value));
                props.selectDoc && props.selectDoc(Number(suggestion.fileurl));
                // console.log('logging selected', suggestion);
              }}>
              {`${suggestion.title}`}
           
            </div>
          ))}
      </div> */}
    </div>
  );
};

export default SearchDoc;
