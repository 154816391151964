/* eslint-disable */
import React, { Component } from 'react';
import { store } from 'react-notifications-component';
import queryString, { parse } from 'query-string';
import Cookies from 'js-cookie';
import axios from 'axios';
import { location } from '../../utility/Location';
import moment from 'moment';
import LoadingSpinner from '../../resources/loadingSpinner';
class Connect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: null,
            result: '',
            loading: false,
        };
    }

    checkurl = () => {
        const parsed = queryString.parse(this.props.location.search);

        const { history } = this.props;

        //this change with respect to each module
        //Edogov_(enter module name);
        const ModuleName = 'Edogov_Reporting';

        //check if there is a jwt token saved before
        const AccessToken = Cookies.get(ModuleName + '_AccessToken');

        //check if there is an app token saved before
        const AppToken = Cookies.get(ModuleName + '_AppToken');

        //get the base url from query string(edogoverp.com)
        const baseurl = parsed.base;

        Cookies.set('baseUrl', baseurl);

        // get the help Link
        Cookies.set('helpLink', parsed.help + '#page=63');

        if (parsed.emp !== '') {
            this.setState({
                loading: true,
            });

            const authurl = '/reporting/api/Authentication/AccessToken'; //change this to the module authenthication endpoint

            axios
                .post(baseurl + authurl + '?Token=' + parsed.emp, {
                    headers: {
                        'Access-Control-Allow-Credentials': true,
                        crossorigin: true,
                        'Access-Control-Allow-Methods': 'POST',
                        'Access-Control-Allow-Origin': '*',
                    },
                })

                .then((user_auth) => {
                    console.log(user_auth.data);
                    Cookies.set(ModuleName + '_UserId', user_auth.data.data.userID);
                    Cookies.set('userId', user_auth.data.data.userID);
                    Cookies.set(ModuleName + '_Role', user_auth.data.data.role);
                    Cookies.set(ModuleName + '_Email', user_auth.data.data.email);
                    Cookies.set(ModuleName + 'profile', user_auth.data.data.profile);
                    //Cookies.set(ModuleName + "_FirstName", user_auth.data.firstName);
                    Cookies.set('_FullName', user_auth.data.data.name);
                    Cookies.set('profilePicture', user_auth.data.data.profilePicture);
                    Cookies.set('isAdmin', user_auth.data.data.isAdmin);
                    Cookies.set('loginTime', moment().format('HH:mm a'));
                    // Cookies.set(ModuleName + "_LastName", user_auth.data.lastName);
                    Cookies.set(ModuleName + '_AppToken', user_auth.data.data.token, { expires: 0.0416665 });
                    Cookies.set(ModuleName + '_HomeLink', user_auth.data.data.homeLink); //for home redirection
                    Cookies.set(ModuleName + '_AccessToken', parsed.emp);
                    Cookies.set('_APIBaseURL', parsed.base);
                    Cookies.set('tokenExist', true, { expires: 0.0416665 });

                    if (AppToken !== null && AppToken !== '') {
                        this.setState({ loading: true });

                        const { history } = this.props;
                        //redirect to dashboard
                        history.push('/admin-portal');
                    } else {
                        store.addNotification({
                            title: 'Error!',
                            message: user_auth.message,
                            type: 'danger',
                            insert: 'bottom',
                            container: 'bottom-left',
                            animationIn: ['animate__animated', 'animate__fadeIn'],
                            animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                                duration: 5000,
                                onScreen: true,
                            },
                        });
                    }
                })

                .catch((error) => {
                    console.log(error);
                    Cookies.set('_AppToken', null);
                    Cookies.set('tokenExist', false);
                    Cookies.set('_FullName', null);
                    Cookies.set('_Role', null);
                    Cookies.set('_Email', null);

                    store.addNotification({
                        title: 'Error!',
                        message: 'You Do Not Have Access To this Application!',
                        type: 'danger',
                        insert: 'bottom',
                        container: 'bottom-left',
                        animationIn: ['animate__animated', 'animate__fadeIn'],
                        animationOut: ['animate__animated', 'animate__fadeOut'],
                        dismiss: {
                            duration: 5000,
                            onScreen: true,
                        },
                    });
                });
        }
        // else if (AppToken !== null && AppToken !== "") {
        //     const { history } = this.props;
        //     //redirect to dashboard
        //     setTimeout(() => history.push('/admin-portal'), 2000);
        // }

        console.log('logging baseurl and Emp', parsed.url, parsed.emp);
    };

    componentDidMount() {
        location();
        this.checkurl();
    }

    render() {
        const { loading } = this.state;
        return (
            <div className='w-100 flex flex-v-center flex-h-center' style={{ minHeight: '105vh' }}>
                {loading ? <LoadingSpinner /> : ''}
            </div>
        );
    }
}

export default Connect;
