import React, { Component } from 'react';
import { IoMdClose } from 'react-icons/io';
import { LoadingSpinner } from '../../resources/loadingSpinner'
import { URLAPI } from '../../utility/ApiMethods'
import { Post, Put, Get } from '../../utility/fetch'
import TextInput from '../inputs/TextInput';
import TextareaInput from '../inputs/TextareaInput';
import Loader from '../../assets/svg/loading.svg';
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';
import { errorMessage, successMessage } from '../../resources/alert';

class GrievanceTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "",
      descriptions: "",
      grievanceID: 0,
      formErrors: {},
      formType: "",
      errorMessage: "",
      button2: 'Add-Type',
      className: '',
      class: "btn btn-large w-100",
      class2: "close-btn pointer",
      loading: false,
      records: [],
      CreatedBy: "",
      visible: true,
      update: false,
      date: "09-Dec-2020 02:41:39 PM",

    }
    this.handleChange = this.handleChange.bind(this);

  }


  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  closeSuccess = () => {
    this.setState({
      ...this.state,
      setSuccess: false,

    })
  }


  onSubmit = e => {
    let error_data = "";

    if (this.state.type === "") {
      error_data = "grievance type cannot be empty";
      this.props.closeModal()
    }
    else if (this.state.descriptions === "") {
      error_data = "description cannot be empty";
      this.props.closeModal()
    }
    if (error_data !== "") {

      errorMessage(error_data);

    }
    else if (error_data === "") {

      let grief = {
        id: this.state.grievanceID,
        grievanceType: this.state.type,
        description: this.state.descriptions,
      }

      if (this.state.button2 === "Add-Type") {
        this.onSubmitSave(grief)
      }
      else if (this.state.button2 === "Update") {
        this.onSubmitUpdate(grief)
      }

    }
  }



  onSubmitSave = (data) => {
    this.setState({ loading: true }, () => {
      Post(data, URLAPI.ReportGrievance.Post, this.onSubmitAfterSave);

    });

  }
  onSubmitAfterSave = (data) => {

    this.props.closeModal()
    if (data.status === "success") {


      successMessage(data.message)

      Get(URLAPI.ReportGrievance.Get, this.props.getGrievances);

      this.setState({
        loading: false,
      })


      //clone the records in array
      const cat = [...this.state.records];

      //create array of data submitted
      let grief = {

        id: data.id,
        grievanceType: this.state.type,
        description: this.state.descriptions,
        //createdBy: this.state.createdBy,
        //status: 1,
      };

      //update the new record into the array
      cat.push(grief);


      //commit the new record into array
      this.setState({ records: cat, loading: false, type: "", descriptions: "" });
    }
    else if (data.status === "failed") {
      //alert(data.message);


      errorMessage(data.message);



      this.setState({ loading: false, visible: false, type: "", descriptions: "", loadingbutton: false });
    }
    else {
      //alert(data.message);
      errorMessage("Grievance Type Already exist")
      this.setState({ loading: false, visible: false, type: "", descriptions: "" });
    }
  }


  onSubmitUpdate = (members) => {

    console.log(members);
    if (members.id !== 0) {
      this.setState({ loading: true }, () => {
        Put(members, URLAPI.ReportGrievance.Put + "/" + members.id, this.onSubmitAfterUpdate);
      });
    }
  }
  onSubmitAfterUpdate = (data) => {


    console.log(data);
    this.props.closeModal()

    if (data.status === "success") {

      //this.props.closeModal()
      // Get(URLAPI.ReportGrievance.Get, this.getGrievances);

      successMessage(data.message)
      Get(URLAPI.ReportGrievance.Get, this.props.getGrievances);
      this.setState({ loading: false, button2: "Add-Type" });

      //get the index of the edited record
      let index = this.state.records.findIndex(x => parseInt(x.id) === parseInt(data.id));

      //clone the record
      let _records = [...this.state.records];

      //check if the record exist
      if (index >= 0) {
        //update the variables with the new ones
        _records[index].grievance = this.state.type;
        _records[index].description = this.state.descriptions
      }

      //commit to state
      this.setState({ records: _records, type: "", descriptions: "", button2: "Add-Type" });


      //this.handleCancel();
    }
    else if (data.status === "failed") {

      errorMessage(data.message)
      this.setState({ loading: false, button2: "Add-Type" });
    }
    else {
      errorMessage("Something went wrong while trying to update record Please, try again later.")
      this.setState({ loading: false, loadingbutton: false, button2: "Add-Type" });
    }
  }



  componentDidMount() {

    Get(URLAPI.ReportGrievance.Get, this.props.getGrievances);
    this.setState({ type: this.props.edittype, descriptions: this.props.description, button2: this.props.button, grievanceID: this.props.typeid, records: this.props.grievance, update: this.props.updatedata });

  }



  render() {
    const { loading, loadingbutton } = this.state
    return (
      <>
        {loading ? <LoadingSpinnerEdo /> :
          <div className="overlay">
            <button type="button" onClick={this.props.closeModal}>
              <IoMdClose className={this.state.class2} />
            </button>
            <div className="modal-box max-w-400" >
              <div className="form-group">
                <div className="content">
                  <TextInput
                    name="type"
                    value={this.state.type}
                    onChange={(e) => this.handleChange(e)}
                    className="w-100 m-b-10"
                    label="Grievance Type"
                    required
                  />
                  <TextareaInput
                    name="descriptions"
                    value={this.state.descriptions}
                    onChange={(e) => this.handleChange(e)}
                    className="w-100 m-b-10"
                    label="Description/Overview"
                    required
                  />
                  {loading ? <LoadingSpinnerEdo /> :
                    <button
                      type="button"
                      className={this.state.class}
                      onClick={this.onSubmit}

                    >
                      {this.state.button2}
                    </button>
                  }
                  <div className="form-group">
                    <div className="text-center" style={{ color: "Red" }}> {this.state.errorMessage}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </>);
  }
}

export default GrievanceTypeForm;