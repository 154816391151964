import React from 'react';
import { MyContext } from '../../context';
import { useContext } from 'react';


var year = new Date().getFullYear();

const Footer = () => {
  const { state, updateState } = useContext(MyContext);

return(

  <footer>
    <div className="content space-between flex-v-center">
      <div className="footer-inner-left">
        <p>{year} {state?.footerText}</p>
      </div>
    </div>
  </footer>
);
} 

export default Footer;
