import * as React from "react";
import ColorController from "../layouts/ColorController";
import DashboardDetails from "../pages/DashboardDetails";
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { BsDisplay } from "react-icons/bs";

const DashboardCard = (props) => {
  // console.log(props.initiatedAt)

  const [dashboardDetail, setdashboardDetail] = React.useState(
    false
  );
//  console.log( moment(props.initiatedAt,"MM-DD-YYYY",true))
console.log(props.comments)

  return (
    <div>
      <div
        className="flex space-between m-t-5"
        data-tip='Click to view details'

        style={{
          boxShadow: "0px 2px 6px #0000000A",
          padding: props.padding || ".6rem",
          fontSize: props.fontSize || "13px",
          cursor: "pointer",
          backgroundColor:
            "#ffffff",
          bgColor: "#fff",
          height: "70px",
          transition: "all 0.6s ease-in",
        }}

        onClick={() => {
          setdashboardDetail(dashboardDetail ? false : true);
        }}
      >
        {console.log("logging comment", props.comment)}
        <div
          style={{ padding: ".4rem", fontSize: "13px",}}
          className="col-6 my-auto"
        >

          {props.title?.toUpperCase() ||
            "Bridge Oyegun - Admin Officer, Community Engagement - Annual Leave Request"}
        </div>
        <div
          style={{ padding: ".4rem", fontSize: "13px" }}
          className="col-6 my-auto"
        >

          {props.role ||
            "Bridge Oyegun - Admin Officer, Community Engagement - Annual Leave Request"}
        </div>

        <div
          className="flex space-around text-center col-4 my-auto"
          style={{ padding: ".4rem" }}
        >
          <div className="m-r-auto flex">
            <div
              className="m-r-5"
              style={{
                width: "1.1rem",
                // backgroundColor: `${props.status ? ColorController.getColor(props.status) : "red"
                //   }`,
                // backgroundColor: `${props.status ? ColorController.getColor(props.status) : "yellow"
                backgroundColor: (props.status=='Approved')?"#1c811c":(props.status=='Pending')?'orange':'Red',
                borderRadius: "50%",
                height: "1.1rem",
              }}
            ></div>
            <div className="my-auto">

             {(props.status=='Pending')? 
             <p>
                {'Currently With: '  + props.name || ""}
              </p>
              : (props.status=='Approved')?
              <p>
              {'Acknowledged'}
            </p>
            :
            <p>
            {'Declined' }
          </p>
              }
            </div>
          </div>
        </div>
        <div
          className="col-2 my-auto"
          style={{
            borderLeft: "1px solid gray",
            padding: ".4rem",
            paddingLeft: ".56rem",
          }}
        >
          <b className="bold-text">Last Treated:</b>
          {props?.initiatedAt?.replace('12:00 AM','') || ""}
        </div>
      </div>
      <ReactTooltip />
      {dashboardDetail && (
        <div>
          <DashboardDetails
            // height={height}
            key={props.id}
            id={props.id}
            name={props.name}
            role={props.role}
            title={props.title.toUpperCase()}
            initiatedAt={props.initiatedAt?.replace('12:00 AM','')}
            initiator = {props.initiator}
            content = {props.content}
            status={props.status}

            // content={props.content}
            //showGrievanceDetail={props.showGrievanceDetail}
            //filterReview={props.filterReviewsOnApprover}
           comment={props.comments}
            documents={props.documents}
            // subject={props.subject}

          />
        </div>
      )}

    </div>

  );
};

export default DashboardCard;
