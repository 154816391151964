import React, { Component } from "react";
import Cookies from "js-cookie";
import LocationDetails from "../layouts/LocationDetails";
import TimeController from '../../resources/TimeController'
import { Get } from '../../utility/fetch'
import { URLAPI } from '../../utility/ApiMethods'
import { LoadingSpinnerEdo } from '../../resources/loadingSpinner';
import FormDateInput from '../inputs/FormDateInput'
import SearchInput from "../inputs/SearchInput";
import { MdEventNote, MdNotifications } from 'react-icons/md';
import { IoMdStats } from "react-icons/io";
import DashboardCard from '../../components/layouts/DashboardCard'
import TablePaginationActions from "../inputs/TablePaginationActions";

const pageTitle = {
  fontSize: "1.3rem",
  font: "font: normal normal 900 19px/25px Avenir;"
};


class Dashboard extends Component {


  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      transist: [],
      loading: true,
      generatedmemo: "",
      finalizedmemo: "",
      completedMemo: [],
      generatedMemozz: [],
      holder: [],
      memoawaiting: "",
      search: null,
      rowsPerPage: 25,
      page: 0,
      dateTo: new Date(),
      dateFrom: new Date("01/01/2022"),
    }
    this.getMemoOnTransist = this.getMemoOnTransist.bind(this)
    this.getCompletedMemo = this.getCompletedMemo.bind(this)
  }



  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };


  handleChangePage = (event, newPage) => {
    this.setState({ ...this.state, page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ ...this.state, rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };


  searchSpace = (event) => {
    let keyword = event.target.value;
    this.setState({ search: keyword })
  }

  getMemoOnTransist = (data) => {
    console.log("Memos:", data);

    if (this._isMounted) {
      let _transist = Array.isArray(data.data) ? data.data : [];
      this.setState({ transist: _transist, holder: _transist, loading: false });
    }

  }
  getCompletedMemo = (data) => {
    console.log("MemosComplete:", data);

    if (this._isMounted) {
      let _complete = Array.isArray(data.data) ? data.data : [];
      this.setState({ completedMemo: _complete, loading: false });
    }

  }

  getStatistics = (data) => {
    console.log(data);
    if (this._isMounted) {
      try {
        this.setState({ loadingData: false, generatedmemo: data?.data?.generatedMemos, finalizedmemo: data?.data?.finalizedMemos, memoawaiting: data?.data?.memosAwaitingReview });
      }
      catch (err) {
        console.log(err);
      }
    }
  }
  // getCompletedMemo = (data) => {
  //   console.log(data);
  //   if (this._isMounted) {
  //     try {
  //       this.setState({ loadingData: false, generatedmemo: data.data.generatedMemos, finalizedmemo: data.data.finalizedMemos, memoawaiting: data.data.memosAwaitingReview });
  //     }
  //     catch (err) {
  //       console.log(err);
  //     }
  //   }
  // }


  componentDidMount() {

    this._isMounted = true;

    Get(URLAPI.MemoOnTransist, this.getMemoOnTransist)
    Get(URLAPI.MemoStatistics, this.getStatistics);
    Get(URLAPI.CompletedMemos, this.getCompletedMemo);
  }

  handlePending = () => {
    console.log(this.state.holder)

    this.setState({ transist: this.state.holder })
  }
  handleApproved = () => {
    console.log(this.state.completedMemo)

    this.setState({ transist: this.state.completedMemo })

  }

  handleGenerated = () => {
    // console.log(this.state.completedMemo)
    this.setState({ transist: [...this.state.holder, ...this.state.completedMemo] })

  }




  render() {

    console.log(this.state.dateTo);
    console.log(this.state.dateFrom);

    const { loading, transist, page,
      rowsPerPage, } = this.state

    const ModuleName = 'Edogov_Reporting';

    const tableData =
      rowsPerPage > 0
        ? transist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : transist;
    return (<>

      <div className="w-100 flex flex-v-center space-between">
        <div>

          <h4 className="m-b-5">
            {TimeController.greet()}<br />
          </h4>

          <h5 style={{ ...pageTitle }} className="m-b-5"> {Cookies.get("_FullName")} </h5>

          <h4 >
            {Cookies.get(ModuleName + "_Role") ||
              ""}
          </h4>
        </div>
        <LocationDetails />
      </div>

      {loading ? <LoadingSpinnerEdo /> :

        <div className="w-100">
          <div className="m-t-20">


            <div className="">
              <div className="row">
                <div className="col-4">
                  <div
                    onClick={this.handleGenerated}
                    className="card pointer" style={{ backgroundColor: 'white' }}>
                    <div className="card card-body">
                      <div class="row">
                        <div class="col-8">
                          <h4>Generated Memo</h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <h1 >{[...this.state.holder, ...this.state.completedMemo].length || 0}</h1>
                        </div>
                        <div class="col-4">
                          <IoMdStats className="statsicon" color="#55D8FE" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    onClick={this.handlePending}
                    className="card pointer" style={{ backgroundColor: 'white' }}>
                    <div className="card card-body">
                      <div class="row">
                        <div class="col-8">
                          <h4>Memos Awaiting Review</h4>
                        </div>

                      </div>
                      <div class="row">
                        <div class="col-8">
                          <h1 >{this.state.holder.length || 0}</h1>
                        </div>
                        <div class="col-4">
                          <MdEventNote className="eventnote" color="#AAA8FB" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    onClick={this.handleApproved}
                    className="card pointer" style={{ backgroundColor: 'white' }}>
                    <div className="card card-body">
                      <div class="row">
                        <div class="col-8">
                          <h4>Finalized Memo</h4>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-8">
                          <h1 >{this.state.completedMemo.length || 0}</h1>
                        </div>
                        <div class="col-4">
                          <IoMdStats className="statsicon" color="#5EE2A0" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="">


            <div className="w-100 m-t-20 flex space-between flex-v-center">
              <h2 className="m-t-20 w-100">Request and Transaction History</h2>
              {/* <div /> */}
              <div style={{ display: "flex ", justifyContent: "flex-end", width: "100%" }}>
                {/* <div style={{display:"flex", }}>
                <div className="approvePending" onClick={this.handlePending} style={{ display:"flex", alignItems:"center"}}>
                <p  className="badger">{this.state.holder.length}</p>
                 
                  <p>
                    Pending Requests
                  </p>
                </div>
                  <div className="approvePending" onClick={this.handleApproved} style={{ display:"flex", alignItems:"center"}}>
                  <div>
                    <div className="badger" >
                    <p className="">{this.state.completedMemo.length}</p>
                    </div>
                  <p>
                  Treated Requests
                  </p>
                  </div>
                </div>
                </div> */}
                <div className="flex"><span className="self-align m-r-5">From: </span><FormDateInput name="dateFrom"  onChange={(e) => this.setState({ dateFrom: new Date(e.target.value) })} /></div>
                <div className="flex m-l-20"><span className="self-align m-r-5">To: </span><FormDateInput name="dateTo"  onChange={(e) => this.setState({ dateTo: new Date(e.target.value) })} /></div>

                <form>
                  <SearchInput onChange={(e) => this.searchSpace(e)} className='m-l-20' />
                </form>
              </div>
            </div>

            <div className="table-view">
              <div className="table-header">
                <div className="table-row">
                  <div className="th" style={{ width: "33%" }}><p className="san-sherif">Title</p></div>
                  <div className="th" style={{ width: "34%" }}><p className="san-sherif">Role</p></div>
                  <div className="th" style={{ width: "26%" }} ><p className="san-sherif">Status</p></div>
                  <div className="th"><p className="san-sherif" >MemoDate</p></div>
                </div>
              </div>
            </div>
            <br />
            {this.state.transist.length === 0 ? <h3> No memo(s) initiated</h3> : (
              <div >
                {tableData?.sort((a, b) => {
                    const dateA = new Date(a?.initiatedAt);
                    const dateB = new Date(b?.initiatedAt);
                    return dateB - dateA;
                  })
                  .filter((data) => {
                    if (this.state.search == null) {
                      console.log(data)

                      return data

                    }
                    else if (data?.title?.toLowerCase().includes(this.state.search.toLowerCase()) || data?.role?.toLowerCase().includes(this.state.search.toLowerCase()) || data?.name?.toLowerCase().includes(this.state.search.toLowerCase())) {
                      console.log(data)
                      return data
                    }

                  })
                  .filter((data) => new Date(data?.initiatedAt) < this.state.dateTo && new Date(data?.initiatedAt) > this.state.dateFrom)
                  .map(
                    ({ id, name, role, title, initiatedAt, initiator, initiatorRole, status, documents, comments, content }) => (
                      <DashboardCard
                        key={id}
                        name={name}
                        role={role}
                        title={title}
                        initiatedAt={initiatedAt}
                        status={status}
                        initiatorRole={initiatorRole}
                        initiator={initiator}
                        comments={comments}
                        // through={through}
                        // status={status}
                        content={content}
                        // memoDate={memoDate}
                        documents={documents}
                      // comment={comments}
                      //filterReview={this.filterReviewsOnApprover()}
                      // showGrievanceDetail={id}
                      />
                    )
                  )}

              </div>
            )}

          </div>
          {transist?.length > 10 && (
            <div className="float-right">
              <TablePaginationActions
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                count={transist.length}
                changeRowsPerPage={this.handleChange}
              />
            </div>

          )}
        </div>

      }

    </>);
  }
}

export default Dashboard;
