class ColorController {
  static getColor = (status) => {

    let firstChar = status.charAt(0).toLowerCase();
    
    if (firstChar === "c") {
      return "#FDCC29";
    } else if (firstChar === "r") {
      return "#EF0621";
    }
    else if (firstChar === "p") {
      return "#FDCC29";
    }
    else {
      return "#0DAC26";
    }
  };
}

export default ColorController;
