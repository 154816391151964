/*eslint-disable */
import React from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { IoMdClose } from 'react-icons/io';
import errorIcon from '../assets/images/error.jpeg';
// import clapIcon from '../assets/images/clapIcon.png';
import { FaSave, FaTrashAlt } from 'react-icons/fa';
import saveIcon from '../assets/images/Save-icon.png';

export const errorMessage = (message) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={{ zIndex: '1000000000' }} className='overlay'>
          <div
            className='modal-box max-w-400'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              position: 'relative',
              textAlign: 'center',
              alignItems: 'center',
            }}>
            <div className='content'>
              <IoMdClose
                className='pointer btn-round'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '15px',
                  fontSize: '24px',
                  color: '#fff',
                  backgroundColor: 'rgba(209, 26, 42, .9)',
                  padding: '3px',
                }}
                onClick={onClose}
              />
              <div className='flex flex-direction-column flex-center mx-auto'>
                <div>
                  <img
                    src={errorIcon}
                    width='65px'
                    alt='Clap'
                    style={{ justifyContent: 'center' }}
                  />
                </div>
                <h3 className='m-t-10' style={{ color: 'red' }}>
                  {message}
                </h3>
              </div>
              <button
                type='button'
                className='btn btn-large w-100 m-t-10 btn-round'
                onClick={onClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    },
  });

  return alert;
};

export const successMessage = (message) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={{ zIndex: '1000000000' }} className='overlay'>
          <div
            className='modal-box max-w-400'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              position: 'relative',
              textAlign: 'center',
              alignItems: 'center',
            }}>
            <div className='content'>
              <IoMdClose
                className='pointer btn-round'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '15px',
                  fontSize: '24px',
                  color: '#fff',
                  backgroundColor: 'rgba(209, 26, 42, .9)',
                  padding: '3px',
                }}
                onClick={onClose}
              />
              <div className='flex flex-direction-column flex-center mx-auto'>
                <div>
                  {/* <img src={clapIcon} width='65px' alt='Clap' /> */}
                </div>
                <h3 className='m-t-10' style={{ textAlign: 'center', alignItems: 'center' }}>
                  {message}
                </h3>
              </div>
              <button
                type='button'
                className='btn btn-large w-100 m-t-10 btn-round'
                onClick={onClose}>
                OK
              </button>
            </div>
          </div>
        </div>
      );
    },
  });
  return alert;
};

export const deleteMessage = (callbackfunction, record) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={{ zIndex: '1000000000' }} className='overlay'>
          <div
            className='modal-box max-w-400'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              position: 'relative',
              borderRadius: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#99A6C4',
            }}>
            <div className='content flex-h-center flex-v-center'>
              <IoMdClose
                className='pointer btn-round'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '15px',
                  fontSize: '24px',
                  color: '#fff',
                  backgroundColor: 'rgba(209, 26, 42, .9)',
                  padding: '3px',
                }}
                onClick={onClose}
              />
              <div style={{ margin: '10px auto' }}>
                <FaTrashAlt fontSize='35px' color='#F6649F' />
              </div>

              <div className='flex flex-direction-column w-100 center-text pd-10'>
                <div className='pd-y-7'>
                  <h2 style={{ color: '#63646E', marginTop: '20px' }}>
                    Are you sure you want to delete
                  </h2>
                  <h2 className='pd-y-7'>this record?</h2>
                </div>
                <div>
                  <p style={{ fontSize: '13px' }}>PS: This process cannot be undone.</p>
                </div>
                <div className='flex space-between m-t-20 pd-10'>
                  <button
                    className='bg-danger btn btn-large w-45'
                    type='button'
                    onClick={() => callbackfunction(record)}>
                    Yes, Delete
                  </button>
                  <button
                    className='bg-light-gray btn btn-large w-45'
                    type='button'
                    onClick={onClose}>
                    No, Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  });
  return alert;
};

export const confirmToSubmit = (callbackfunction, record) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div style={{ zIndex: '1000000000' }} className='overlay'>
          <div
            className='modal-box max-w-400'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              position: 'relative',
              borderRadius: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#99A6C4',
            }}>
            <div className='content flex-h-center flex-v-center'>
              <IoMdClose
                className='pointer btn-round'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '15px',
                  fontSize: '24px',
                  color: '#fff',
                  backgroundColor: 'rgba(209, 26, 42, .9)',
                  padding: '3px',
                }}
                onClick={onClose}
              />
              <div style={{ margin: '10px auto' }}>
                <FaSave fontSize='35px' color='#F6649F' />
              </div>

              <div className='flex flex-direction-column w-100 center-text pd-10'>
                <div className='pd-y-7'>
                  <h2 style={{ color: '#63646E', marginTop: '20px' }}>
                    Are you sure you want to submit
                  </h2>
                  <h2 className='pd-y-7'>this record?</h2>
                </div>
                <div>
                  <p style={{ fontSize: '13px' }}>PS: This process cannot be undone.</p>
                </div>
                <div className='flex space-between m-t-20 pd-10'>
                  <button
                    className='bg-danger btn btn-large w-45'
                    type='button'
                    onClick={() => callbackfunction(record)}>
                    Yes, Submit
                  </button>
                  <button
                    className='bg-light-gray btn btn-large w-45'
                    type='button'
                    onClick={onClose}>
                    No, Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  });
  return alert;
};

export const promptSubmitSave = (callbackfunction, callbackfunction2, record, record2) => {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div
          className='modal-box max-w-400'
          style={{
            boxShadow: '0px 2px 6px #0000000A',
            position: 'relative',
            textAlign: 'center',
            alignItems: 'center',
          }}>
          <div
            className='modal-box max-w-400'
            style={{
              boxShadow: '0px 2px 6px #0000000A',
              backgroundColor: 'white',
              position: 'relative',
              borderRadius: '10px',
              alignItems: 'center',
              justifyContent: 'center',
              color: '#99A6C4',
            }}>
            <div className='content flex-h-center flex-v-center'>
              <IoMdClose
                className='pointer btn-round'
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '15px',
                  fontSize: '24px',
                  color: '#fff',
                  backgroundColor: 'rgba(209, 26, 42, .9)',
                  padding: '3px',
                  cursor: 'pointer',
                }}
                onClick={onClose}
              />
              <div className='flex flex-direction-column w-100 center-text pd-10'>
                <div className='flex flex-direction-column flex-center mx-auto'>
                  <div>
                    <img src={saveIcon} width='65px' alt='Save' />
                  </div>

                  <h3
                    className='m-t-10'
                    style={{ color: 'red', textAlign: 'center', alignItems: 'center' }}>
                    Confirm To Save Or Submit Grievance
                  </h3>
                </div>
                <div className='flex space-between m-t-20 pd-10'>
                  <button
                    className='btn btn-small w-100 m-t-10'
                    type='button'
                    onClick={() => callbackfunction(record)}>
                    Save Grievance
                  </button>
                  <button
                    className='btn btn-small w-100 m-t-10'
                    type='button'
                    style={{ marginLeft: '6px' }}
                    onClick={() => callbackfunction2(record2)}>
                    submit Grievance
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
  });
  return alert;
};

export function passwordPop(callbackfunction, getValue) {
  const alert = confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='card'>
          <div className='card-header separator' style={{ background: 'whitesmoke' }}>
            Authenticate Process
          </div>
          <div className='card-body'>
            <div className='form-group'>
              <label>Enter Password</label>
              <input type='password' onChange={getValue} className='form-control' />
            </div>
            <div className='form-group'>
              <button className='btn btn-danger' onClick={onClose}>
                Cancel
              </button>
              <button className='btn btn-success pull-right' onClick={callbackfunction}>
                Ok
              </button>
            </div>
          </div>
        </div>
      );
    },
  });

  return alert;
}

export default {
  passwordPop,
  errorMessage,
  successMessage,
  deleteMessage,
  confirmToSubmit,
};
