import React, { useState, useContext } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
//import { RiLogoutCircleLine } from 'react-icons/ri';
import { AiOutlineHome } from 'react-icons/ai';
import { FiHelpCircle } from 'react-icons/fi';
import logo from '../../assets/images/Group-2.png'


import EdsgLogo from '../../assets/images/edsg-logo-250.png';

import { logout } from '../../utility/logout';
import { MyContext } from '../../context';
import LoadingSpinnerEdo from '../../resources/loadingSpinner';
import Spinner from '../../assets/svg/Spinner';

const Sidebar = ({ history, menuList }) => {
  const {
    location: { pathname },
  } = history;

  const { state, updateState } = useContext(MyContext);


  const handleLogout = async () => {
    await logout();
  };

  console.log(`data:image/png;base64,${state?.logo}`)
  console.log(state)

  return (
    <nav className='page-sidebar'>
      {state?.mainTitle ? <div className="sidebar-header flex">
        <img src={`data:image/png;base64,${state?.logo}`} alt="logo" className="brand" width="60" />
        <div>
          {/* <img src={greenzone} alt="logo" className="brand" width="100" /> */}
          <h1 style={{ color: "green", fontWeight: 800 }}>{state?.mainTitle.toUpperCase()}</h1>
          <h1 style={{ color: "green", fontWeight: "bolder" }}>{state?.subtitle.toUpperCase()}</h1>
        </div>
      </div> : <Spinner />}

      <div className='sidebar-menu'>
        <ul className='menu-items'>
          <li>
            <AiOutlineHome className='icon' />
            <span onClick={handleLogout} role='presentation' className='has-sub-menu pointer'>
              <span className='title'>Home</span>
            </span>
          </li>
          <li>
            <FiHelpCircle className='icon' />
            <Link
              to={{
                pathname: `${Cookies.get('_APIBaseURL')}${Cookies.get(
                  'helpLink',
                )}`,
              }}
              target='_blank'>
              <span role='presentation' className='has-sub-menu pointer'>
                <span className='title'>User Manual</span>
              </span>
            </Link>
          </li>
          {menuList &&
            menuList.map((item) => <MenuItem props={item} pathname={pathname} key={item.title} />)}
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ props: { title, href, icon, children }, pathname }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li className={`${pathname === href ? 'active' : ''}`}>
        {icon}
        {children ? (
          <>
            <span
              onClick={() => setIsShowingSub(!isShowingSub)}
              role='presentation'
              className='has-sub-menu pointer'>
              <span className='title'>{title}</span>
            </span>
            {children && <IoIosArrowBack className={`${isShowingSub ? 'open' : ''} arrow`} />}
          </>
        ) : (
          <Link to={href}>
            <span className='title'>{title}</span>
          </Link>
        )}
      </li>
      {children && isShowingSub && (
        <ul className={`${isShowingSub ? 'show' : ''} sub-menu`}>
          {children &&
            children.map((sub) => (
              <li className={`${pathname === sub.href ? 'active' : ''}`} key={sub.title}>
                <Link to={sub.href}>{sub.title}</Link>
              </li>
            ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
