import React from 'react';
import { Switch, Route } from 'react-router';
import { pure } from 'recompose';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../components/pages/Dashboard';
import Logout from '../components/pages/Logout';
import GrievanceType from '../components/pages/GrievanceType';
import ApprovalSetup from '../components/pages/ApprovalSetup';
import ReportGrievance from '../components/pages/ReportGrievance';
import GrievanceReviews from '../components/pages/GrievanceReviews';
import Home from '../components/pages/Home';
import SessionHoc from '../components/hoc/SessionHOC'



export default pure(() => (
  <Switch>
    <ProtectedRoute
      path="/admin-portal/home"
      exact
      component={Home}
    />


    <ProtectedRoute
      path="/admin-portal/"
      exact
      component={SessionHoc(Dashboard)}
    />


    <ProtectedRoute
      path="/admin-portal/report-grievance"
      exact
      component={SessionHoc(ReportGrievance)}
    />

    <ProtectedRoute
      path="/admin-portal/grievance-reviews"
      exact
      component={SessionHoc(GrievanceReviews)} />

    <ProtectedRoute
      path="/admin-portal/grievance-type"
      exact
      component={SessionHoc(GrievanceType)}
    />

    <ProtectedRoute
      path="/admin-portal/approval-setup"
      exact
      component={SessionHoc(ApprovalSetup)}
    />

    <ProtectedRoute
      path="/admin-portal/logout"
      exact
      component={Logout}
    />


    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
