/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Cookies from "js-cookie";
import { RiBarChartFill, RiUserSettingsLine } from 'react-icons/ri';
import { BiEnvelope } from 'react-icons/bi';
//import { HiOutlineUserAdd } from 'react-icons/hi';
//import { CgUserList } from 'react-icons/cg';
import { AiOutlineHome } from 'react-icons/ai';
import { IoIosInformation, IoIosLogOut, IoIosMail, IoIosSad, IoIosSettings, IoIosStar } from 'react-icons/io';
import { BsFillPersonFill } from 'react-icons/bs';

console.log(Cookies.get("isAdmin"))

let sidebar = [];

if (Cookies.get("isAdmin") == 'true' ) {
  sidebar = [
    //  { title: 'Home', href: '/admin-portal/home', icon: <AiOutlineHome className="icon" /> },
    { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className="icon" /> },
    { title: 'Report Grievance', href: '/admin-portal/report-grievance', icon: <IoIosSad className="icon" /> },
    { title: 'Grievance Review', href: '/admin-portal/grievance-reviews', icon: <IoIosStar className="icon" /> },


    {
      title: 'Setup',
      icon: <IoIosSettings className="icon" />,
      children: [
        { title: 'Grievance Type', href: '/admin-portal/grievance-type' },
        { title: 'Approval Setup', href: '/admin-portal/approval-setup' },
      ]
    },

    {
      title: 'Log Out', href: '/admin-portal/logout', icon: <IoIosLogOut className="icon" />
    },
  ]
}
else {
  sidebar = [
    //  { title: 'Home', href: '/admin-portal/home', icon: <AiOutlineHome className="icon" /> },
    { title: 'Dashboard', href: '/admin-portal', icon: <RiBarChartFill className="icon" /> },
    { title: 'Report Grievance', href: '/admin-portal/report-grievance', icon: <IoIosSad className="icon" /> },
    { title: 'Grievance Review', href: '/admin-portal/grievance-reviews', icon: <IoIosStar className="icon" /> },


    // {
    //   title: 'Setup',
    //   icon: <IoIosSettings className="icon" />,
    //   children: [
    //     { title: 'Grievance Type', href: '/admin-portal/grievance-type' },
    //     // { title: 'Approval Setup', href: '/admin-portal/approval-setup' },
    //   ]
    // },

    {
      title: 'Log Out', href: '/admin-portal/logout', icon: <IoIosLogOut className="icon" />
    },
  ]
}

export default sidebar
