// index.js
import axios from 'axios';
import React, { createContext,useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
  

// Create a context
const MyContext = createContext();

// Create a provider component
const MyProvider = ({ children }) => {
  const [state, setState] = useState({});

  useEffect(()=>{
    getOrganizationMetadata()
  }, [])


  const getOrganizationMetadata = () =>{
     axios.get(`https://edogoverp.com/egovresourceapi/organizationresource/${process.env.REACT_APP_ORGANIZATION_ID}`)
     .then((res)=>{
        console.log(res)
        setState(res?.data);
     })
  }
 

  const updateState = (newState) => {
    setState(newState);
  };

  return (
    <MyContext.Provider value={{ state, updateState }}>
      {children}
    </MyContext.Provider>
  );
};

export{MyProvider, MyContext};